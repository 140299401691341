<script setup lang="ts">
    import type { FooterMenu } from '~/components/atm/FooterMenu.vue';

    const { locale, iso2Country, iso2Lang, isUS } = useLocales();
    const appStore = useAppStore();
    await appStore.checkWebsite({ locale, iso2Country, iso2Lang });
    const { website, footer } = storeToRefs(appStore);

    const footerMenuReferences = computed(() => {
        return footer.value?.menu?.references;
    });
    const footerMenu = computed(() => {
        return footer.value?.menu?.items
            ?.map((menuItemId) => {
                const { id, title, color, items: itemIds, link } = getItemById(menuItemId, footerMenuReferences?.value) ?? {};
                if (!id || !title) return null;
                const items = itemIds
                    ?.map((menuItemId) => {
                        return getItemById(menuItemId, footerMenuReferences?.value);
                    })
                    ?.filter(isDefined);
                return {
                    id,
                    title,
                    color,
                    link,
                    items: items as FooterMenu[],
                };
            })
            ?.filter(isDefined);
    });

    const footerSubMenuRefs = computed(() => {
        return footer.value?.submenu?.references;
    });
    const footerSubMenu = computed(() => {
        return footer.value?.submenu?.items
            ?.map((submenuItemId) => {
                return getItemById(submenuItemId, footerSubMenuRefs.value);
            })
            ?.filter(isDefined);
    });

    const contact = computed(() => {
        return footer.value?.contact;
    });
</script>

<template>
    <footer class="mol-footer bg-woom-white-smoke py-12 md:py-16 lg:py-20">
        <atm-grid>
            <template
                v-for="(item, index) in footerMenu"
                :key="item.id">
                <template v-if="item?.items">
                    <div :class="['col-span-2 max-md:-mt-4', isUS ? 'lg:col-span-3' : ' md:max-lg:col-span-4']">
                        <atm-footer-menu
                            :item="item"
                            :index="index" />
                    </div>
                </template>
            </template>

            <div :class="['col-span-2 flex flex-col gap-4', isUS ? ' md:max-lg:col-span-4 lg:col-span-3' : 'md:max-lg:col-span-8']">
                <atm-heading
                    level="h2"
                    size="xs">
                    <atm-contact-button class="font-bold [&[href]]:hover:underline" />
                </atm-heading>

                <div
                    v-if="contact?.value"
                    v-html="contact.value"
                    class="contact"></div>
            </div>

            <div
                :class="[
                    'col-span-2 gap-4',
                    isUS
                        ? 'flex flex-col md:col-span-12 md:max-lg:flex-row lg:col-span-3 lg:col-start-10'
                        : 'md:col-span-4 md:col-start-9 lg:col-span-3 lg:col-start-10 lg:flex lg:flex-col lg:items-end',
                ]">
                <cms-content-controller
                    v-if="website?.footer?.newsletter"
                    :data="website.footer.newsletter"
                    class="flex flex-col justify-center gap-4" />

                <div class="flex justify-end gap-2">
                    <atm-if-country :countries="['at']">
                        <atm-at-top-rated2024 />
                    </atm-if-country>
                    <lazy-atm-trusted-shops />
                </div>
            </div>

            <div class="col-span-2 md:col-span-12">
                <atm-social-links />
            </div>

            <div class="col-span-2 flex flex-col gap-4 md:col-span-9 lg:gap-1 xl:col-span-10">
                <ul
                    v-if="footerSubMenu?.length"
                    class="flex flex-wrap text-sm">
                    <template
                        v-for="item in footerSubMenu"
                        :key="item.id">
                        <li
                            v-if="item && item.title && (item.link?.url?.url || item.link?.data?.url)"
                            class="after:mx-2 after:my-0 after:content-['|'] after:last:content-['']">
                            <nuxt-link
                                :to="$helpers.generateLocalePath(item?.link.url?.url || item?.link?.data?.url)"
                                class="inline-flex py-1 transition-colors hover:text-woom-red">
                                {{ item?.title }}
                            </nuxt-link>
                        </li>
                    </template>
                    <li class="after:mx-2 after:my-0 after:content-['|'] after:last:content-['']">
                        <!-- https://help.datareporter.eu/docs/webcare/consent_settings -->
                        <a
                            href="javascript:dr_revokeChoice();"
                            class="inline-flex py-1 transition-colors hover:text-woom-red">
                            {{ $t('cta.cookieSettings') }}
                        </a>
                    </li>
                </ul>
                <small class="font-mono text-sm text-woom-black">{{ $t('copyright', { year: new Date().getFullYear() }) }}</small>
            </div>

            <div class="col-span-2 md:flex md:flex-col md:items-end md:max-xl:col-span-3 md:max-xl:col-start-10 xl:col-start-11">
                <atm-language-button theme="footer" />
            </div>

            <div class="col-span-2 flex flex-wrap gap-2 md:col-span-12">
                <atm-shop-icons icon-type="payment" />
                <atm-shop-icons icon-type="shipment" />
            </div>
        </atm-grid>
    </footer>
</template>

<style scoped>
    :deep(.contact) p + p {
        @apply mt-4;
    }
    :deep(.contact) a {
        @apply transition-colors hover:text-woom-red hover:underline;
    }
    /* TODO: could probably be removed in https://onewoom.atlassian.net/browse/ECOMM-1076 */
    :deep(.text-block) {
        @apply mx-0 pt-0;
    }
    :deep(.text-block) p:has(a),
    :deep(.cms-content-controller) > a {
        @apply rounded-lg bg-white p-4 text-center;
    }
</style>
